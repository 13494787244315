import { RouteMetaInfo } from "@/types/route-config";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import store from '@/store';

export function authenticationGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {

  const meta = to.meta as RouteMetaInfo | undefined;
  const allowAnonymous = meta?.anonymous ?? false;  
  if (allowAnonymous) { next(); return; } // no need to check authentication

  const isAuthenticated = store.state.authentication.session != null;

  if (!isAuthenticated) {
    if (to.name === 'login') { next(); return; } // already headed to login, don't redirect
    next({ name: 'login' });
    return;
  }
  next();
}
