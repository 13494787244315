import firebase from 'firebase';
// import * as firebase from 'firebase/app';
import { AuthenticationError, AuthenticationErrorCode } from './types/AuthenticationError';


export interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  [property: string]: any;
}

class InternalError extends Error {
  readonly name = 'InternalError';
  constructor(message: string, public code?: string | undefined) {
    super(message);
  }
}


export class FirebaseAuthenticationClient {
  private app: firebase.app.App;
  constructor(
    firebaseConfig: IFirebaseConfig,
    // private type: 'custom' | 'id' = 'custom',
  ) {
    // if (!firebase.apps.length) {
      this.app = firebase.initializeApp(firebaseConfig);
    // }
  }

  public async signIn(email: string, password: string): Promise<string> {
    try {
      const auth = this.app.auth();
      const cred = await auth.signInWithEmailAndPassword(email, password);
      if (cred == null) throw new InternalError('Expecting a Credential'); // not expected (should throw error first)
      if (cred.user == null) throw new InternalError('Expecting a User'); // not expected
      const token = await cred.user?.getIdToken();
      if (token == null) throw new InternalError('Expecting a Token');

      // TODO: Validate Token Here
      // console.log('tokenLength', token.length);
      
      return token;
    } catch (error: any) {
      throw this.convertError(error);
    }
  }

  private convertError(error: any): AuthenticationError {

    if (error.name === 'AuthenticationError') { 
      return error;
    }

    if (error.name === 'InternalError') {
      // TODO: determine if these should be logged
      return new AuthenticationError('Unable to Log In', AuthenticationErrorCode.Other);
    }

    const code = error?.code;
    if (code != null) {
      switch (code) {
        // common firebase errors
        case 'auth/user-not-found': return new AuthenticationError('Invalid Username or Password', AuthenticationErrorCode.Fail);
        case 'auth/wrong-password': return new AuthenticationError('Invalid Username or Password', AuthenticationErrorCode.Fail); // NOTE: firebase returns 'wrong-password' for valid user accounts. This may be a security concern since it confirms an account exists.
        case 'auth/invalid-email' : return new AuthenticationError('Invalid Email Address', AuthenticationErrorCode.BadEmail);
      }
    }

    return new AuthenticationError('Unable to Log In', AuthenticationErrorCode.Other);
  }

  public async signOut() {
    const auth = this.app.auth();
    await auth.signOut();
  }

}

export const firebaseAuthenticationClient = new FirebaseAuthenticationClient({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || '',
});
