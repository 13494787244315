"use strict";

import { Vue, Options } from "vue-class-component";
import { Store } from "vuex";
import { IRootState } from "./store/root/types";

class VueExt extends Vue {
  $store!: Store<IRootState>;
}

export { VueExt as Vue , Options };
export { Prop, Watch, Provide, Inject, ProvideReactive, State, Getter, Action, Mutation, namespace } from "./lib/vue-decorators";

export class Page extends Vue {}

/*
export {
    // Component,
    Emit,
    Inject,
    InjectReactive,
    Model,
    ModelSync,
    Prop,
    PropSync,
    Provide,
    ProvideReactive,
    Ref,
    VModel,
    Watch,
    // Vue,
    // Mixins,
  } from "vue-property-decorator"
*/
