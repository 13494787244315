import { createApp } from "vue";
import AppRoot from "@/AppRoot.vue";
import "./registerServiceWorker";
import router from "./router/router";
import store from "./store";
import { registerUiComponents } from "./webstack/helpers/register-ui-components";

const app = createApp(AppRoot)
app.use(store);
app.use(router);

registerUiComponents(app);

app.mount("#app");