
import { Vue, Options, Prop } from '@/common';
import UiIcon from './ui-icon.vue';

@Options({ 
    components: { 
        UiIcon
    },
    emits:['click'],
})
export default class UiIconButton extends Vue {
    @Prop({ type: String }) readonly icon: string | undefined;
}

