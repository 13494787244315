
import { Vue, Options, Prop, Watch } from '@/common';
import { UiIconDefinition } from '../types/icons';
import IconHelper from '../helpers/icon-helper';

@Options({ 
    components: { },
    emits:['click'],
})
export default class UiIcon extends Vue {

    @Prop({ type: String }) readonly src: string | undefined;
    @Prop({ type: String }) readonly icon: string | undefined;

    private currentIcon: string | undefined;
    private currentSrc: string | undefined;
    public renderedIconHTML: string | null = null;

    beforeMount(): void {
        this.updateIcon(this.icon, this.src);
    }

    mounted() {
        this.renderIcon();
    }
    
    updated() {
        this.renderIcon();
    }

    private renderIcon() {
        if (this.$el) {
            this.$el.innerHTML = this.renderedIconHTML ?? '';
        }
    }

    @Watch('icon')
    handleIconChange(): void {
        this.updateIcon(this.icon, this.src);
        this.renderIcon();
    }


    // Helpers

    private async updateIcon(iconId: string | undefined, src: string | undefined) {

        if (iconId) {
            if (this.currentIcon === iconId) return;
            this.currentIcon = iconId;
            if (await this.updateIconId(iconId)) return; // success
            this.clearIcon();
            return;
        }

        if (this.src) {
            if (this.currentSrc === src) return;
            this.currentSrc = src;
            if (await this.loadSvgFromFile(src)) return; // success
            this.clearIcon();
            return;
        }

        this.clearIcon();
    }

    private async updateIconId(iconId: string): Promise<boolean> {
        if (iconId == null) { return false; }

        // const icon = this.uiService.findIcon(iconId);
        const icon = IconHelper.getIcon(iconId);
        if (!icon) { return false; }

        if (icon.src) {
            return await this.loadSvgFromFile(icon.src);
        }

        if (icon.path) {
            this.buildSvgFromPath(icon);
            return true;
        }

        if (icon.html) {
            this.buildSvgFromHtml(icon);
            return true;
        }

        return false;
    }


    private clearIcon() {
        // this.renderedIconHTML = this.sanitizer.bypassSecurityTrustHtml('');
        this.renderedIconHTML = '';
    }


    private buildSvgFromHtml(icon: UiIconDefinition) {
        if (!icon.html) { throw new Error('Missing Icon HTML'); }
        // this.renderedIconHTML = this.sanitizer.bypassSecurityTrustHtml(icon.html);
        this.renderedIconHTML = icon.html;
    }

    private async loadSvgFromFile(src: string | undefined): Promise<boolean> {
        // TODO: Cache loaded files

        if (!src) return false;
        const response = await fetch(src);
        if (response.ok) {
        const html = await response.text();
        // TODO: Sanitize content. Verify this is an SVG.
        if (html.includes('<svg')) {
            // this.renderedIconHTML = this.sanitizer.bypassSecurityTrustHtml(html);
            this.renderedIconHTML = html;
        } else {
            // this.renderedIconHTML = this.sanitizer.bypassSecurityTrustHtml('');
            this.renderedIconHTML = '';
        }

        return true;
        }
        return false;
    }

    private buildSvgFromPath(icon: UiIconDefinition) {
        if (!icon.path) { throw new Error('Missing Icon Path'); }
        if (!icon.width) { throw new Error('Missing Icon Width'); }
        if (!icon.height) { throw new Error('Missing Icon Height'); }

        if (typeof icon.path !== 'string') {
            throw new TypeError('Expected a single string path. Array of paths not implemented.');
        }

        const isStroke = (icon.stroke != null);

        const html = '<svg xmlns="http://www.w3.org/2000/svg" width="' + icon.width + '" height="' + icon.height + '" viewBox="0 0 ' + icon.width + ' ' + icon.height + '" '+ ( isStroke ? 'fill="none" stroke="currentColor"' : 'fill="currentColor"') + '>'
        + this.getSvgPathElement(icon)
        + '</svg>';

        // this.renderedIconHTML = this.sanitizer.bypassSecurityTrustHtml(html);
        this.renderedIconHTML = html;
    }

    private getSvgPathElement(icon: UiIconDefinition) {
        const props: {[key: string]: string | number } = {};
        if (Array.isArray(icon.path)) { throw new TypeError('Expecting a single path'); }

        if (icon.stroke != null) {
            if (icon.stroke.lineCap) { props['stroke-linecap'] = icon.stroke.lineCap; }
            if (icon.stroke.lineJoin) { props['stroke-linejoin'] = icon.stroke.lineJoin; }
            props['stroke-width'] = icon.stroke.width;
        }

        props.d = icon.path ?? '';

        const list: string[] = [];
        for (const key in props) { list.push(key + '="' + props[key]  + '"'); }

        return `<path ${ list.join(' ') }/>`;
    }

}

