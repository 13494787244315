
import { Vue, Options, Prop } from '@/common';

@Options({
  components: {
  },
})
export default class NavItem extends Vue {
  @Prop({ type: String, default: ''}) title!: string;
  @Prop({ type: String, default: null}) icon!: string | null;
  @Prop({ type: Boolean, default: false}) expanded!: boolean;
  @Prop({ type: Boolean, default: false}) active!: boolean;
}
