
import { Vue, Options } from '@/common';

@Options({ 
    components: { 
    } 
})
export default class UiHeading extends Vue {
}

