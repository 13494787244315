import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71200a80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-form-input" }
const _hoisted_2 = {
  key: 0,
  class: "field-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_input = _resolveComponent("ui-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_ui_input, {
      modelValue: _ctx.textValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textValue) = $event)),
      name: _ctx.name,
      type: _ctx.type,
      "allow-show-password": _ctx.allowShowPassword
    }, null, 8, ["modelValue", "name", "type", "allow-show-password"])
  ]))
}