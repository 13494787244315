
import { Vue, Options, Inject } from '@/common';
import { AuthenticationService } from '../services/AuthenticationService';
import NavItem from './NavItem.vue';

@Options({
  components: {
    NavItem
  },
})
export default class LeftNavigation extends Vue {
  @Inject() authenticationService!: AuthenticationService;
  isExpanded = false;

  async logOut() {
    await this.authenticationService.signOut();
    this.$router.push({ name: 'login' });
  }

}
