

import { Page, Options } from '@/common'


@Options({
    components: {
    },
})
export default class NotAuthorizedPage extends Page { 
}
