
import { Vue, Options } from '@/common';
import { Inject } from '@/lib/vue-decorators';
import { AuthenticationService } from '@/modules/shared/services/AuthenticationService';  

@Options({
  components: {
  },
})
export default class LoginPage extends Vue {
  isBusy = false;
  email = '';
  password = '';

  @Inject() authenticationService!: AuthenticationService;
  
  async logIn() {
    this.isBusy = true;
    try {
      
      const userSession = await this.authenticationService.signIn(this.email, this.password);
      if (userSession != null) {
        this.$router.push({ name: 'accounting.dashboard' });
      }
    } catch (error: any) {
      console.warn(error.message);
    }
    this.isBusy = false;
  }
  

  // activeBrand = process.env.brand!;

  // get logo() {
  //   const brand = this.activeBrand.toLowerCase();
  //   return require(`@/assets/logos/${brand} - Logo.svg`);
  // }

  // resetPassword() {
  //   this.$router.push('/ui/forgot-pw');
  // }

  // goToSignup() {
  //   this.$router.push('/ui/enroll');
  // }

  // @Watch('form.data.password')
  // updateMe(pw: string) {
  //   // console.log(pw);
  //   // set();    
  // }

  get isFormComplete(): boolean {
    return (this.email.length > 0) && (this.password.length > 0);
  }

}
