
import { Vue, Options } from '@/common';
import UiConfig from '@/ui-config';

@Options({
  components: {
  },
})
export default class TopHeader extends Vue {
  get logo(): string {
    return UiConfig.logoSvg;
  }
}
