
import { Vue, Options } from '@/common';

@Options({ 
    components: { 
    } 
})
export default class UiPlaceholder extends Vue {
}

