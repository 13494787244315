
import { Vue, Options } from '@/common';
import TopHeader from '@/modules/shared/components/TopHeader.vue';
import LeftNavigation from '@/modules/shared/components/LeftNavigation.vue';

@Options({
  components: {
    TopHeader,
    LeftNavigation,
  },
})
export default class MainLayout extends Vue {
}
