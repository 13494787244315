import { UserSession } from '@/modules/shared/types/UserSession';
import { MutationTree } from 'vuex';
import { IAuthenticationState } from './types';

export const mutations: MutationTree<IAuthenticationState> = {

  session(state, session: UserSession | null) {
    state.session = session;
  },

};
