
import { Vue, Options, Prop, Watch } from '@/common';

@Options({ 
    components: { },
    emits:['click'],
})
export default class UiSvg extends Vue {
  @Prop({ type: String }) readonly src: string | undefined;
  @Prop({ type: Boolean, default: false }) readonly hideUnloaded!: boolean;

  ready = false;

  async mounted() {
    this.reset();
  }

  @Watch('src')
  onSrcChange() {
    this.reset();
  }

  async reset() {
    if (!this.src) return;
    if (!this.$el) return;
    this.$el.innerHTML = '';
    this.ready = false;
    const response = await fetch(this.src);
    if (!response.ok) return;
    const text = await response.text();
    if (text.includes('<svg')) {
      const div = this.$el.ownerDocument.createElement('div');
      div.innerHTML = text;

      while (this.$el.firstChild) { this.$el.removeChild(this.$el.firstChild); }            
      
      const svgEl = div.firstElementChild;
      if (svgEl) {
        const children: any[] = [];
        for (let j=0; j<svgEl.children.length; j++) { children[j] = svgEl.children[j]; }
        for (const child of children) { this.$el.appendChild(child); }
        for (let j=0; j<svgEl.attributes.length; j++) { 
            const attr = svgEl.attributes[j];
            if (attr.name.toLowerCase() !== 'class') {
                this.$el.setAttribute(attr.name, attr.value);
            }
        }
      }
      this.ready = true;
    } else {
        this.$el.innerHTML = '';
        this.ready = false;
    }
  }

  get show(): boolean {
    return this.hideUnloaded ? this.ready : true; 
  }
    
}

