
import { Vue, Options, Provide } from '@/common';
import MainLayout from '@/modules/shared/layouts/MainLayout.vue';
import { AuthenticationService } from './modules/shared/services/AuthenticationService';
// import EmptyLayout from '@/modules/shared/layouts/EmptyLayout.vue';
import { RouteMetaInfo } from './types/route-config';
// import { authenticationService } from '@/modules/shared/services/AuthenticationService';

@Options({
  components: {
    MainLayout,
  },
})
export default class AppRoot extends Vue {

  get layout(): string | null {
    const meta = this.$route.meta as any as RouteMetaInfo;
    switch(meta.layout) {
      case 'main': return 'main-layout';
    }
    return 'router-view'; // empty
  }

  // authService!: AuthenticationService
  @Provide() authenticationService = new AuthenticationService();

// @Provide('authenticationService') getAuthenticationService = () => this.authenticationService;
  

  async beforeMount() {
    await this.$store.dispatch('init');
    await this.authenticationService.init(this.$store);
    // this.authService = new AuthenticationService(this.$store);
    
  }

}
