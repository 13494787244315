import { UiConfiguration } from "@/types/ui-configuration";

const config: UiConfiguration = {
  // BrandConfiguration
  id: "connect",
  apiCode: 'CONNECT',
  brandName: "Connect",
  color: '#4373B8',
  logoMarkSvg: require("@/assets/brands/connect/connect-logo-mark.svg"),

  // UiConfiguration
  logoSvg: require("@/assets/brands/connect/connect-logo.svg"),
};

export default config;
