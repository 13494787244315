
import { Vue, Options, Prop } from '@/common';

@Options({ 
    components: { },
    emits:['click'],
})
export default class UiButton extends Vue {
  @Prop() title: string | undefined; // supports title or ng-content
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) primary!: boolean;
  @Prop({ type: Boolean, default: false }) ghost!: boolean;
  @Prop({ type: Boolean, default: false }) busy!: boolean;

  buttonClicked(evt: MouseEvent) {
    if (this.disabled) return;
    if (this.busy) return;
    this.$emit('click', evt);
  }

}
