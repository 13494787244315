// import _ from 'lodash';

import {
  // UiIconDefinition,
  UiIconSet,
  UiIconStrokeOptions,
} from './types/icons';

const outline2px: UiIconStrokeOptions = {
  width: 2,
  lineCap: 'round',
  lineJoin: 'round',
};

// prettier-ignore
const Icons: UiIconSet = {
  'hamburger-14': { width: 14, height: 14, path: 'M0 2a1 1 0 011-1h12a1 1 0 011 1 1 1 0 01-1 1h-12a1 1 0 01-1-1zM0 7a1 1 0 011-1h12a1 1 0 011 1 1 1 0 01-1 1h-12a1 1 0 01-1-1zM0 12a1 1 0 011-1h12a1 1 0 011 1 1 1 0 01-1 1h-12a1 1 0 01-1-1z' },
  'switch': { width: 24, height: 24, path: 'M22 4h-12v-3l-8 6 8 6v-3h12ZM2 14h12v-3l8 6-8 6v-3h-12Z' },
  'connect': { width: 66, height: 50, path: 'M56.5 12.9c-4.7-7.2-13.3-12.1-23.3-12.1s-18.6 4.8-23.3 12c-9.5 3.5-9.3 12.2-9.3 12.2s-.2 8.6 9.3 12c4.7 7.3 13.3 12.2 23.3 12.2s18.7-5 23.3-12.3c9.2-3.5 9-12 9-12s.3-8.4-9-12zM51.1 34.9c-8.1 2.7-18 2.7-18 2.7s-9.9 0-18-2.7c-8.2-2.8-8-10-8-10s-.2-7.2 8-10 18-2.7 18-2.7 9.9 0 18 2.7 8 10 8 10 .2 7.3-8 10l0 0zM18.4 24.9a1 1 0 009.6 0 1 1 0 00-9.6 0M37 24.9a1 1 0 009.6 0 1 1 0 00-9.6 0' },
  'exclamation': { width: 12, height: 12, path: 'M7 9a1 1 90 11-2 0 1 1 90 012 0zM6 2a1 1 90 00-1 1v3a1 1 90 002 0v-3a1 1 90 00-1-1z' },

  // Hero Outline
  'hi-home': { width: 24, height: 24, stroke: outline2px, path: 'M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6' },
  'hi-currency-dollar': { width: 24, height: 24, stroke: outline2px, path: 'M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z' },
  'hi-presentation-chart-line': { width: 24, height: 24, stroke: outline2px, path: 'M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z' },
  'hi-fire': { width: 24, height: 24, stroke: outline2px, path: 'M17.7 18.7a8 8 0 01-11.4-11.4s.7 1.7 2.7 2.7c0-2 .5-5 3-7 2 2 4.1 2.8 5.7 4.3a8 8 0 012.3 5.7 8 8 0 01-2.3 5.7zM9.9 16.1a3 3 0 102.1-5.1l-1 3h-2c0 .8.3 1.5.9 2.1z' },
  'hi-clock': { width: 24, height: 24, stroke: outline2px, path: 'M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' },
  'hi-bell': { width: 24, height: 24, stroke: outline2px, path: 'M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9' },
  'hi-x': { width: 24, height: 24, stroke: outline2px, path: 'M6 18L18 6M6 6l12 12' },
  'hi-check': { width: 24, height: 24, stroke: outline2px, path: 'M5 13l4 4L19 7' },
  'hi-logout': { width: 24, height: 24, stroke: outline2px, path: 'M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1' },
  'hi-clipboard': { width: 24, height: 24, stroke: outline2px, path: 'M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2' },

  // Hero Solid
  'hi-eye': { width: 24, height: 24, path: 'M.458 10c1.274-4.057 5.064-7 9.542-7s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7s-8.268-2.943-9.542-7zM14 10a4 4 0 00-8 0 4 4 0 008 0z' },
  'hi-eye-off': { width: 24, height: 24, path: 'M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473a10.014 10.014 0 003.308-4.82c-1.274-4.057-5.064-7-9.542-7a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zM7.968 6.553a4 4 0 015.478 5.478l-1.514-1.514a2.003 2.003 0 00-2.45-2.45zM12.454 16.697l-2.704-2.705a4 4 0 01-3.742-3.741l-3.673-3.673a9.98 9.98 0 00-1.877 3.422c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z' },

}

/*
function cloneOutline(sourceId: string, strokeWidth: number): UiIconDefinition {
  const source = Icons[sourceId];
  if (!source) { throw new Error('Icon not found: ' + sourceId); }
  const newIcon = _.cloneDeep(source);
  if (newIcon.stroke) { newIcon.stroke.width = strokeWidth; }
  else { newIcon.stroke = { width: strokeWidth}; }
  return newIcon;
}

Icons['hil-home'] = cloneOutline('hi-home', 1.25);
Icons['hil-currency-dollar'] = cloneOutline('hi-currency-dollar', 1.5);
Icons['hil-presentation-chart-line'] = cloneOutline('hi-presentation-chart-line', 1.5);
Icons['hil-fire'] = cloneOutline('hi-fire', 1.5);
Icons['hil-bell'] = cloneOutline('hi-bell', 1.5);
Icons['hil-clock'] = cloneOutline('hi-clock', 1.5);
*/

export default Icons;
