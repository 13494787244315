
import { Vue, Options, Prop, Watch, Inject } from '@/common';
import { UiForm, UiFormField } from '../form';
import UiInput, { UiInputType } from './ui-input.vue';

@Options({ 
    components: {
      UiInput,
    },
    emits: ['update:modelValue'],
})
export default class UiFormInput extends Vue {
  @Prop({ type: String, default: ''}) label!: string;
  @Prop({ type: String, default: 'text'}) type!: UiInputType;
  @Prop({ type: Boolean, default: false}) allowShowPassword!: boolean;

  // option 1 (use v-model)
  @Prop() modelValue!: string;

  // TODO: option 2 (use UiForm)... not fully reactive for change detection
  @Inject({default: undefined}) form: UiForm | undefined;
  @Prop({ type: String, default: null}) name!: string | null; // field name when used with form

  field: UiFormField | undefined;
  textValue = '';

  beforeMount() {
    this.textValue = this.modelValue;
    if (this.useForm) {
      this.field = this.form!.getField(this.name!);
      this.textValue = this.field.value as string ?? '';
    }
  }

  @Watch('value')
  onValueChange(val: string):void {
    this.textValue = val;
  }

  @Watch('textValue')
  onTextValueChange(val: string):void {
    if (this.field) { this.field.value = val; }
    this.$emit('update:modelValue', val);
  }

  public hasLabel(): boolean {
    if (this.label == null) return false;
    return this.label.trim() !== '';
  }

  get useForm(): boolean {
    return this.name != null && this.form != null
  }

}

