import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90b09e62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-input" }
const _hoisted_2 = ["type", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textValue) = $event)),
      type: _ctx.currentType,
      name: _ctx.name,
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus',$event))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur',$event)))
    }, null, 40, _hoisted_2), [
      [_vModelDynamic, _ctx.textValue]
    ]),
    (_ctx.type === 'password' && _ctx.allowShowPassword)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "toggle-pass-icon",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.togglePassword($event)))
        }, [
          _createVNode(_component_ui_icon, { icon: _ctx.eyeIcon }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true)
  ]))
}