import { createStore } from "vuex";
import { authenticationModule } from "./authentication";

const store = createStore({
  modules: {
    authentication: authenticationModule,
  },
  actions: {
    async init(context) {
      await context.dispatch('authentication/init');
    }
  }
});

export default store;