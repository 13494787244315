import { ActionTree } from 'vuex';
import { IRootState } from '../root/types';
import { IAuthenticationState } from './types';

export const actions: ActionTree<IAuthenticationState, IRootState> = {

  init(context) {
    //
  },

};
