import { App } from 'vue';
import UiIcon from '../components/ui-icon.vue';
import UiIconButton from '../components/ui-icon-button.vue';
import UiSvg from '../components/ui-svg.vue';
import UiButton from '../components/ui-button.vue';
import UiHeading from '../components/ui-heading.vue';
import UiInput from '../components/ui-input.vue';
import UiFormInput from '../components/ui-form-input.vue';
import UiPlaceholder from '../components/ui-placeholder.vue';

export function registerUiComponents(app: App) {
  app.component('UiIcon', UiIcon);
  app.component('UiIconButton', UiIconButton);
  app.component('UiSvg', UiSvg);
  app.component('UiButton', UiButton);
  app.component('UiHeading', UiHeading);
  app.component('UiInput', UiInput);
  app.component('UiFormInput', UiFormInput);
  app.component('UiPlaceholder', UiPlaceholder);
}