import { RouteMetaInfo } from "@/types/route-config";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import store from '@/store';

export function adminGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {

  const meta = to.meta as RouteMetaInfo | undefined;
  const requireAdmin = meta?.requireAdmin ?? false;  
  if (!requireAdmin) { next(); return; } // no need to check

  const isAdmin = store.state.authentication.session?.isAdmin ?? false;

  if (!isAdmin) {
    if (to.name === '403') { next(); return; } // already headed to 403, don't redirect
    next({ name: '403' });
    return;
  }

  next();
}
