import { Module } from "vuex";
import { IRootState } from "../root/types";
import { IAuthenticationState } from "./types";
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const authenticationModule: Module<IAuthenticationState, IRootState> = {
  namespaced: true,
  state: {
    session: null,
  },
  getters,
  mutations,
  actions,
};

export { authenticationModule };
