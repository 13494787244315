import { AuthenticationError, AuthenticationErrorCode } from '@/lib/authentication/types/AuthenticationError';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import loginMutation from './login.gql';

export interface WalletServerAuthenticationClientConfig {
  server: string;
}

export interface WalletServerLoginResult {
  token: string;
  profile: {
    id: string,
    email: string,
    currency: string,
    phone?: string,
    phoneCountry?: string,
  }
  mfaEnabled: boolean,
  walletExists: boolean,
}

export class WalletServerAuthenticationClient {

  /*
  constructor(private config: WalletServerAuthenticationClientConfig) {
    if (config.server == null) {
      throw new AuthenticationError('Missing  Server Address', AuthenticationErrorCode.Other);
    }
  }
  */

  public async signInWithToken(firebaseToken: string): Promise<WalletServerLoginResult> {

    if (!firebaseToken) { 
      throw new AuthenticationError('Expecting a Token', AuthenticationErrorCode.Other); 
    } 

    // TODO: validate token

    // TODO: refactor to an injected or persisted client
    try {
      const client = new ApolloClient({
        uri: process.env.VUE_APP_SERVER,
        cache: new InMemoryCache(),
      });
  
      const fetchResult = await client.mutate({
        mutation: loginMutation,
        variables: {
          token: firebaseToken,
        }
      });
      if (!fetchResult) { throw new Error(); }

      const loginRes = fetchResult.data?.login;
      const token = loginRes?.token;
      if (token == null) { throw new Error(); }

      const { profile } = loginRes;

      const result: WalletServerLoginResult = {
        profile: {
          id: profile.id,
          email: profile.email,
          currency: profile.currency,
          phone: profile.phone ?? undefined,
          phoneCountry: profile.phoneCountry ?? undefined,
        },
        mfaEnabled: loginRes.twoFaEnabled ?? false,
        walletExists: loginRes.walletExists ?? false,
        token: token,
      }

      return result;
    } catch (error) {
      throw new AuthenticationError('Login Failed', AuthenticationErrorCode.Other); 
    }
  }

}
