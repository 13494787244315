import { RouteRecordRaw } from "vue-router";
import { RouteConfigExtension } from "@/types/route-config";

const routes: (RouteRecordRaw & RouteConfigExtension)[] = [

  {
    path: "/accounting",
    redirect: { name: 'accounting.dashboard' },
  },

  {
    name: 'accounting.dashboard',
    path: "/accounting/dashboard",
    meta: { layout: 'main', requireAdmin: true },
    component: () => import(/* webpackChunkName: "accounting" */ "@/modules/accounting/pages/AccountingDashboardPage.vue"),
  },

  {
    name: 'accounting.dashboard.all',
    path: "/accounting/dashboard/all",
    meta: { layout: 'main', requireAdmin: true },
    component: () => import(/* webpackChunkName: "accounting" */ "@/modules/accounting/pages/AccountingDashboardPage.vue"),
  },

];

export default routes;
