// Port of 'vue-property-decorator' which does not seem to be updated for Vue 3.0

import { Vue, createDecorator, PropOptions } from 'vue-class-component';


/**
 * decorator of a prop
 * @param  options the options for the prop
 * @return PropertyDecorator | void
 */
 export function Prop(options: PropOptions /* | Constructor[] | Constructor */ = {}) {
    return (target: Vue, key: string) => {
        // applyMetadata(options, target, key)
        createDecorator((componentOptions, k) => {
            (componentOptions.props || ((componentOptions.props = {}) as any))[ k ] = options
        })(target, key)
    }
}