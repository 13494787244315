import { AuthenticationError, AuthenticationErrorCode } from '@/lib/authentication/types/AuthenticationError';
import { firebaseAuthenticationClient } from '@/lib/authentication/FirebaseAuthenticationClient';
import { WalletServerAuthenticationClient, WalletServerLoginResult } from './WalletServerAuthenticationClient';
import { TOKEN_COOKIE_NAME } from '@/environment';
import * as jwt from 'jsonwebtoken';
import { UserSession } from '../types/UserSession';
import { Store } from 'vuex';
import { IRootState } from '@/store/root/types';

export class AuthenticationService {

  private store!: Store<IRootState>;

  /*
  constructor(private store: Store<IRootState>) {

  }
  */

  public async signIn(username: string, password: string): Promise<UserSession> {

    const firebaseToken = await firebaseAuthenticationClient.signIn(username, password);
    if (!firebaseToken) { throw new AuthenticationError('Login Failed', AuthenticationErrorCode.Other); } 
    
    const walletServerClient = new WalletServerAuthenticationClient();
    const result = await walletServerClient.signInWithToken(firebaseToken);
    if (!(result && result.token && result.profile)) { throw new AuthenticationError('Login Failed', AuthenticationErrorCode.Other); } 

    const token = result.token;
    const decodedToken = jwt.decode(token);
    if (decodedToken == null) { throw new AuthenticationError('Invalid Token', AuthenticationErrorCode.InvalidToken); } 

    try {
      const session = this.getUserSessionForLogin(token, result);
      this.setTokenCookie(token);
      this.setSessionState(session);
      return session;
    } catch (error) {
      throw new AuthenticationError('Login Failed', AuthenticationErrorCode.Other); 
    }
  }

  public setSessionState(session: UserSession) {
    this.store?.commit('authentication/session', session);
  }

  public async init(store: Store<IRootState>) {
    this.store = store;
    const token = this.getTokenCookie();
    if (token == null) return;

    const decodedToken = jwt.decode(token) as any;
    if (decodedToken) {

      const claims = decodedToken.claims;
      const session: UserSession = {
        profile: {
          name: ((claims?.firstName ?? '') + ' ' + (claims?.lastName ?? '')).trim(),
          id: '',
          email: '',
          currency: '',
          phone: '',
          phoneCountry: '',
        },
        mfaEnabled: false,
        walletExists: false,
        token: token,
        isAdmin: decodedToken.claims?.role === 'admin',
      };

      this.setSessionState(session);
    }
  }

  private getUserSessionForLogin(token: string, result: WalletServerLoginResult): UserSession {

    const decodedToken = jwt.decode(token) as any;
    if (decodedToken == null) { throw new AuthenticationError('Invalid Token', AuthenticationErrorCode.InvalidToken); } 

    const profile = result.profile;
    const session: UserSession = {
      profile: {
        name: '',
        id: profile.id,
        email: profile.email,
        currency: profile.currency,
        phone: profile.phone,
        phoneCountry: profile.phoneCountry,
      },
      mfaEnabled: result.mfaEnabled,
      walletExists: result.walletExists,
      token: token,
      isAdmin: decodedToken.claims?.role === 'admin',
      
    }
    return session;
  }

  private getCookieDomain(): string {
    const { hostname } = window.location;
    return hostname === 'localhost' ? 'localhost' : hostname.split('.').slice(-2).join('.');
  }

  private setTokenCookie(token: string): void {
    // if (!process.browser) { return; } will this ever NOT be running in the browser
    const domain = this.getCookieDomain();
    const now = new Date();
    now.setTime(now.getTime() + 3600 * 1000);
    const expiration = now.toUTCString();
    document.cookie = `${TOKEN_COOKIE_NAME}=${token}; domain=${domain}; expires=${expiration};`;
  }

  private deleteTokenCookie(): void {
    const domain = this.getCookieDomain();
    console.log(`${TOKEN_COOKIE_NAME}=; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`);
    document.cookie = `${TOKEN_COOKIE_NAME}=; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }
  

  private getTokenCookie(): string {
    return this.getCookie(TOKEN_COOKIE_NAME);
  }

  private getCookie(cname: string): string {
    const name = cname.toLowerCase() + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  
    
  public async signOut(): Promise<void> {
    this.deleteTokenCookie();
    await firebaseAuthenticationClient.signOut();
  }

}

// export const authenticationService = new AuthenticationService();
