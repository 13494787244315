
import { Vue, Options, Prop, Watch } from '@/common';
import UiIcon from './ui-icon.vue';


export type UiInputType = 'text' | 'password' | 'tel' | 'email' | 'url';

@Options({ 
    components: {
      UiIcon,
    },
    emits: ['focus', 'blur', 'update:modelValue'],
})
export default class UiInput extends Vue {
  @Prop() modelValue!: string;
  @Prop({ type: String, default: 'text'}) type!: UiInputType;
  @Prop({ type: Boolean, default: false}) allowShowPassword!: boolean;
  @Prop({ type: String, default: null}) name!: string | null;

  textValue = '';
  isPasswordVisible = false;

  beforeMount() {
    this.textValue = this.modelValue;
  }

  togglePassword(evt: MouseEvent) {
    evt.preventDefault();
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  get currentType(): UiInputType {
    if (this.type === 'password' && this.allowShowPassword && this.isPasswordVisible) {
      return 'text';
    }
    return this.type;
  }

  get eyeIcon(): 'hi-eye' | 'hi-eye-off'  {
    return this.isPasswordVisible ? 'hi-eye-off' : 'hi-eye';
  }

  @Watch('value')
  onValueChange(val: string):void {
    this.textValue = val;
  }

  @Watch('textValue')
  onTextValueChange(val: string):void {
    this.$emit('update:modelValue', val);
  }

}

