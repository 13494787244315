import { RouteConfigExtension } from "@/types/route-config";
import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import NotFoundPage from "@/modules/shared/pages/NotFoundPage.vue";
import NotAuthorizedPage from "@/modules/shared/pages/NotAuthorizedPage.vue";
import LoginPage from "@/modules/shared/pages/LoginPage.vue";
import accountingRoutes from "@/modules/accounting/routes";
import { notFoundGuard } from "./guards/not-found-guard";
import { authenticationGuard } from "./guards/authentication-guard";
import { adminGuard } from "./guards/admin-guard";

const routes: Array<RouteRecordRaw & RouteConfigExtension> = [

  {
    name: 'root',
    path: "/",
    meta: { anonymous: true },
    redirect: { name: 'accounting.dashboard' }
  },

  {
    name: 'login',
    path: "/login",
    meta: { anonymous: true },
    component: LoginPage,
  },

  {
    name: '404',
    path: "/404",
    meta: { anonymous: true },
    component: NotFoundPage,
  },

  {
    name: '403',
    path: "/403",
    meta: { anonymous: true },
    component: NotAuthorizedPage,
  },

  ...accountingRoutes,

];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// Guards
router.beforeEach(notFoundGuard);
router.beforeEach(authenticationGuard);
router.beforeEach(adminGuard);

export default router;
