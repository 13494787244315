import { UserContext } from '@/modules/shared/types/UserContext';
import { UserSession } from '@/modules/shared/types/UserSession';
import { GetterTree } from 'vuex';
import { IRootState } from '../root/types';
import { IAuthenticationState } from './types';

export const getters: GetterTree<IAuthenticationState, IRootState> = {

  session(state: IAuthenticationState): UserSession | null {
    return state.session;
  },

  userContext(state: IAuthenticationState): UserContext {
    return {
      token: state.session?.token,
    };
  },

};
